/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import dashboardStyles from '../dashboard/DashboardStyling'
import menuStyles from './MenuStyles'

class CloseMenu extends React.Component {
  render() {
    const { menuCollapsed } = this.props;
    return (
      <div>
        <div>
          {/* <a
            style={menuStyles.menuCollapsedArrolwColor}
            className="nav-link"
            data-widget="pushmenu"
            href="#"
            role="button"
          >
            {menuCollapsed ? (
              <i className="fas fa-chevron-right" />
            ) : (
              <i className="fas fa-chevron-left" />
            )}
          </a> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    getLocations: state.getLocations,
    menuCollapsed: state.menuCollapsed.menuCollapsed,
  };
};

export default connect(mapStateToProps)(CloseMenu)
