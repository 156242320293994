import { Auth } from "aws-amplify";

const initialState = {
  userData: ''
}

const getCurrentUser = (state = initialState, action) => {
  const newState = { ...state }
  Auth.currentAuthenticatedUser().then((data) => {
    if(data){
      newState.userData = data.attributes
    } else {
      newState.userData = null
    }
  }).catch((err) => console.log(err))
  return newState
};

export default getCurrentUser;
