/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import styles from "../../AuthStyling";
import dashboardStyles from "./DashboardStyling";
import { NavLink } from "react-router-dom";
import { locationApi } from "../helpers/api_services/fetchLocations";
import CloseMenu from '../menu/closeMenu';
import CompanyStatus from './display/topLocations/companyStatus';
import GraphLayout from './display/graph/graphLayout'
import JobOpenings from './display/jobOpenings/jobOpenings'
import RecyclopsPricing from './display/recyclopsPricing/recyclopsPricing'
import Teams from './display/teams/teams'
import DashboardHeader from './dashboardHeader'
import TopStatusBoxes from './display/topStatusBoxes/topStatusBoxes'


class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date().toLocaleString().split(',')[0],
      listOfstateArr: [],
      numberOfStates: 0
    };
  }

  async componentDidMount() { // api call for location
    try {
      const fetchApi = await locationApi();
      const locationData = await fetchApi;
      if (locationData) {
        let locationDataAlpha = locationData.sort((a, b) =>
          a.state > b.state ? 1 : b.state > a.state ? -1 : 0
        );
        this.setState({
          numberOfStates: locationData.length,
          listOfstateArr: [...locationData]
        });
      }
    } catch (e) {
      return null;
    }
  }

  render() {
    const { listOfstateArr, numberOfStates, date } = this.state
    return (
      <div style={dashboardStyles.dashboard_padding_Left}>
        <div
          className="content-wrapper"
          style={dashboardStyles.dashboard_background_color}
        >
          {/* Main content */}
          <section className="content">
            <div className="container-fluid">

              <br />
              <div className=''>
                <DashboardHeader date={date} getCurrentUser={this.props.getCurrentUser} />
              </div>

              <TopStatusBoxes numberOfStates={numberOfStates} />
              <br />

              <div className="row">

                <section className="col-lg-7 connectedSortable">

                  <div className="card" style={dashboardStyles.graphTable}>
                    {/* /.card-header */}

                    <div className="card-body">
                      <div style={dashboardStyles.middleBoxesTitleFont}>
                        <div style={{ paddingLeft: '1.5%' }}>
                          Sign ups
                        </div>
                      </div>
                      <br />

                      <div style={{ fontSize: "95%", paddingLeft: '5%' }}>

                        <span>Customers</span>
                        <span style={{ paddingLeft: '2%' }}>Drivers</span>
                        <span style={{ paddingLeft: '75%' }}>Monthly</span>

                      </div>

                      {/* graph Table goes here*/}

                      <br />
                      <br />
                      <GraphLayout />
                    </div>
                  </div>
                </section>
                <section className="col connectedSortable">

                  <div className="card " style={dashboardStyles.statusTable}>
                    <div className="card-header border-0" >
                      <CompanyStatus />
                    </div>
                    <div className="card-body"><div />
                    </div>
                  </div>

                </section>

              </div>
            </div>
            <div style={{ marginBottom: '3%' }}>

              <div >
                <div className="row" style={{ padding: '1%' }}>

                  <div className="col-lg-3">
                    <div style={dashboardStyles.bottomBoxStatus1}>
                      <JobOpenings />
                    </div>

                  </div>

                  <div className="col-lg-6" >
                    <div style={dashboardStyles.bottomBoxStatus2}>
                      <RecyclopsPricing />
                    </div>
                  </div>

                  <div className="col-lg-3" >
                    <div style={dashboardStyles.bottomBoxStatus3}>
                      <Teams />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>

        </div>
        <br />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    getLocations: state.getLocations,
    menuCollapsed: state.menuCollapsed.menuCollapsed,
  };
};

const mapDispachToProps = (dispach) => {
  return {
    menuCollapsedBool: () => dispach({ type: "MENU_COLLAPSE" }),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(Dashboard)
