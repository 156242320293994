/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import Amplify, { Auth } from "aws-amplify";

class NotRecyclopsEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount = () => {
    // Auth.signOut()
  }
  render() {
    return (
      <div>
        <div>
          {/* Content Header (Page header) */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-10">
                  <h1 className="m-0">Not Recyclops Employee
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotRecyclopsEmployee
