/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
// import dashboardStyles from '../../DashboardStyling'

const componentStyling = {
    titlePadding: {
        padding: '6%',
      },
    contentPadding: {
        padding: '0% 6% 0% 5%'
    },
    title_font:{
        fontWeight: 'bold',
        fontSize: '105%'
    },
    centerDivider1: {
        borderRight:'1px solid #2A9B8D',
        height:'20px',
    },
    centerDivider2: {
        borderLeft:'1px solid #2A9B8D', 
        height:'20px',
    },
    divider: {
        // marginTop: '1rem',
        marginBottom: '1rem',
        border: 0,
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
      }
}

class Teams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div>
        <div 
        // style={dashboardStyles.BottomBoxesLayoutTopColor}
        >
            <div style={componentStyling.titlePadding}>
                <div className='container'>
                    <div style={componentStyling.title_font}>Teams</div>
                </div>
            </div>
        </div>
            <div style={componentStyling.contentPadding} >
    
            {/*  */}
            <table className="table">
                <tbody>
                    <tr>
                    <td>Mark</td>
                    <td>Otto</td>
                    </tr>

                    <tr>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    </tr>

                    <tr>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    </tr>
                </tbody>
            </table>
            {/*  */}


                
            </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    getLocations: state.getLocations,
    menuCollapsed: state.menuCollapsed.menuCollapsed,
  };
};

export default connect(mapStateToProps)(Teams);
