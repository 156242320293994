/* eslint-disable */
import { Auth } from "aws-amplify";

const initialState = {
  name: "",
  lastName: "",
  phoneNumber: "", //custom attribute
};

const updateUserProfile = (state = initialState, action) => {
  const newState = { ...state };
  let name = "";
  let phoneNumber = "";
  let lastName = "";

  if (action) {
    for (let key in action.updatedData) {
      if (
        action.updatedData.name.length > 0 ||
        action.updatedData.phoneNumber.length > 0 ||
        action.updatedData.lastName.length > 0
      ) {

        name = action.updatedData.name;
        phoneNumber = action.updatedData.phoneNumber;
        lastName = action.updatedData.lastName

        newState.name = name;
        newState.phoneNumber = phoneNumber;
        newState.lastName = lastName;
      }
    }
    if (action.type === "UPDATE_AWS_USER") {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          if (user) {

            if (newState.name.length > 0) {
              Auth.updateUserAttributes(user, {
                name: newState.name,
              });
            }

            if (newState.phoneNumber) {
              Auth.updateUserAttributes(user, {
                "custom:phoneNumber": newState.phoneNumber,
              });
            }

            // if (newState.lastName) {
            //   Auth.updateUserAttributes(user, {
            //     "custom:lastName": newState.lastName,
            //   });
            // }

          }
        })
        .catch((err) => console.log(err));
    }
  }


  return newState;
};

export default updateUserProfile;
