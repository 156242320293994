/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import dashboardStyles from "../../DashboardStyling";

const sampleTable = [
  { name: 'utah',  ranking: '2243'},
  { name: 'Michigan',  ranking: '1245'},
  { name: 'Kansas',  ranking: '1113'},
  { name: 'tenesse',  ranking: '943'},
  { name: 'Texas',  ranking: '947'},
  { name: 'Georigia',  ranking: '876'},
  { name: 'Mississippi',  ranking: '800'},
  { name: 'Florida',  ranking: '745'},
  { name: 'Alabama',  ranking: '723'},
  { name: 'Arizona',  ranking: '700'},
]

class CompanyStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  sampleTableStatus(data, index) {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{data.name}</td>
        <td>{data.ranking}</td>
      </tr>
    )
  }

  render() {
    return (
      <div className='container'>
        <div style={dashboardStyles.middleBoxesTitleFont}>Top Locations</div>
        <div>Active Customers</div>
        <br />
        <div style={{borderTop: "2px solid grey"}}></div>
        <table className="table">
          <thead>
            {/* column title goes here */}
          </thead>
          <tbody>
            {sampleTable.map(this.sampleTableStatus)}
          </tbody>
        </table>
        <div className='text-center'>Check All</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    getLocations: state.getLocations,
    menuCollapsed: state.menuCollapsed.menuCollapsed,
  };
};

export default connect(mapStateToProps)(CompanyStatus);
