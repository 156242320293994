const dashboardStyles = {
  dashboard_theme: {
    background: "#2A9B8D"
  },
  dashboard_padding_Left: {
    padding: '0% 0% 0% 1%'
  },
  dashboard_background_color: {
    backgroundColor: "#F5F5F5",
  },
  left_padding_dashboard: {
    paddingLeft: "32230%",
    width: '98%',
    height: '199%'
  },
  greeting_font: {
    fontSize: "45px",
    color: "#000000",
    fontWeight: "bold",
    paddingTop: "1%",
    paddingLeft: "1%",
  },
  date_styling: {
    paddingLeft: "1%",
    color: "#838383",
  },
  graphLayout: {
    background: "rgba(245, 42, 42, 0.2)",
  },
  head_tiles_color_red: {
    width: "97%",
    height: "90%",
    background: "rgba(245, 42, 42, 0.2)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    marginBottom: "10%",
    marginRight: "2%",
    marginLeft: "2%",
  },
  head_tiles_color_light_green: {
    width: "97%",
    height: "90%",
    background: "rgba(60, 180, 170, 0.3)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    marginBottom: "10%",
    marginRight: "2%",
    marginLeft: "2%",
  },
  top_tile_status_font: {
    color: "#000000",
    height: "21px",
    fontWeight: "normal",
    fontSize: "14px",
    letterSpacing: "0.06em",
    paddingLeft: '7%',
    paddingTop: "3%",
  },
  top_tile_text: {
    margin: "auto",
    width: "50%",
    paddingRight: "87%",
    paddingTop: "4%",
    fontSize: "120%",
    fontWeight: "bold",
  },
  top_tile_numbers_font: {
    fontSize: "40px",
    fontWeight: "bold",
  },
  search_bar_styling: {
    width: "210px",
    height: "39px",
    // left: "1159px",
    // top: "60px",
    background: "#FFFFFF",
    border: "1px solid #B8B8B8",
    boxSizing: "border-box",
    borderRadius: "20px",
  },
  search_bar_location: {
    paddingTop: "5%",
    paddingLeft: "35%",
  },
  statusTable: {
    fontSize: '80%',
    height: '550px',
    marginLeft: '43%',
    background: 'linear-gradient(180deg, rgba(252, 200, 22, 0.2) 0%, rgba(252, 200, 22, 0) 100%)',
    borderRadius: '20px 20px 20px 20px',
    backgroundColor: "#FFFFFF",
  },
  graphTable : {
    width: '128%',
    height: '97%',
    borderRadius: '20px 20px 20px 20px',
  },
  graphTableLayout : {
    width: "350px",
    height: "200px",
    left: "255px",
    top: "162px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    marginBottom: "30px",
    marginRight: "2%",
    marginLeft: "2%",
  },
  BottomBoxesLayoutTopColor: {
    background: 'linear-gradient(180deg, rgba(252, 200, 22, 0.2) 0%, rgba(252, 200, 22, 0) 98.96%)',
    borderRadius: '20px 20px 0px 0px',
    paddingBottom: '5%'
    
  },
  bottomBoxStatus1 : {
    width: "100%",
    height: "100%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    paddingBottom: '20%',
    backgroundColor: "#FFFFFF",
  },
  bottomBoxStatus2 : {
    width: "96.8%",
    height: "100%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    marginLeft: '2%',
    backgroundColor: "#FFFFFF",
  },
  bottomBoxStatus3 : {
    width: "100%",
    height: "100%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    marginLeft: '3%',
    backgroundColor: "#FFFFFF",
  },
  middleBoxesTitleFont: {
    fontSize: "140%", 
    fontWeight: "bold"
  }
};

export default dashboardStyles