/* eslint-disable */
import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'March',
    uv: 280,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'April',
    uv: 330,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'May',
    uv: 305,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'June',
    uv: 300,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'July',
    uv: 450,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'August',
    uv: 480,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'September',
    uv: 530,
    pv: 4300,
    amt: 2100,
  },
];

class GraphLayout extends PureComponent {

  render() {
    return (
      <div>GRAPGH GOES HERE</div>
      // <ResponsiveContainer height="70%" >
      //   <AreaChart
      //     width={500}
      //     height={100}
      //     data={data}
      //     margin={{
      //       top: 10,
      //       right: 30,
      //       left: 0,
      //       bottom: 0,
      //     }}
      //   >
      //     <CartesianGrid strokeDasharray="3 3" />
      //     <XAxis dataKey="name" />
      //     <YAxis />
      //     <Tooltip />
      //     <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="green" />
      //   </AreaChart>
      // </ResponsiveContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    getLocations: state.getLocations,
    menuCollapsed: state.menuCollapsed.menuCollapsed,
  };
};

export default connect(mapStateToProps)(GraphLayout);
