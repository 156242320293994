/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import CloseMenu from "../menu/closeMenu";
import Amplify, { Auth } from "aws-amplify";

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async signOut() {
    const user = await Auth.signOut();
  }

  render() {
    return (
      <div>
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <div onClick={this.props.menuCollapsedBool}>
            <CloseMenu />
          </div>
          <div className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-10">
                  <h1 className="m-0">Logout Page</h1>
                  <button onClick={() => this.signOut()}>
                    Click here to logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    getLocations: state.getLocations,
    menuCollapsed: state.menuCollapsed.menuCollapsed,
  };
};

const mapDispachToProps = (dispach) => {
  return {
    menuCollapsedBool: () => dispach({ type: "MENU_COLLAPSE" }),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(Logout);
