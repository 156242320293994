import React from "react";
import dashboardStyles from '../dashboard/DashboardStyling'

class Footer extends React.Component {
  render() {
    return (
      <div>
        <footer className="main-footer" style={dashboardStyles.dashboard_background_color}>
          <h1>
            <a href="https://recyclops.com/">Recyclops</a>.
          </h1>
          <strong>
            {`All Contents ©2021 Recyclops – All Rights Reserved `}
          </strong>
        </footer>
      </div>
    );
  }
}

export default Footer
