/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import dashboardStyles from '../../DashboardStyling'

const componentStyling = {
    titlePadding: {
        padding: '7% 0% 0% 7%',
      },
    contentPadding: {
        padding: '0% 0% 0% 6%'
    },
    title_font:{
        fontWeight: 'bold',
        fontSize: '105%'
    }
}

class JobOpenings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div>
        <div style={dashboardStyles.BottomBoxesLayoutTopColor}>
            <div style={componentStyling.titlePadding}>
                <div className='container'>
                    <div style={componentStyling.title_font}>Job Openings</div>
                </div>
            </div>
        </div>
            <div style={componentStyling.contentPadding}>
                <div className='container'>
                    <div>Operations (3)</div>
                    <div>Information Technology (2)</div>
                    <div>Marketing (1)</div>
                </div>
            </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    getLocations: state.getLocations,
    menuCollapsed: state.menuCollapsed.menuCollapsed,
  };
};

export default connect(mapStateToProps)(JobOpenings);
