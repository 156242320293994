/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import dashboardStyles from '../../DashboardStyling'
import GraphBars from './graphBars'
import symbolwhite from "../../../../symbolwhite.png";
import styles from '../../../../AuthStyling'

const componentStyling = {
  statusInfoPadding: {
    paddingLeft: "4%"
  },
  percentageStatusPadding: {
    paddingTop: "4%"
  }
}

class TopStatusBoxes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const { numberOfStates } = this.props
    return (
      <div className="row">
        <div className="col-lg-3 col-6">
          {/* small box */}
          <div style={dashboardStyles.head_tiles_color_light_green}>

            <div className='container'>
              <div className="row">
                <div className="col-6" style={componentStyling.statusInfoPadding}>
                  <div
                    className="inner"
                    style={dashboardStyles.top_tile_text}
                  >
                    <div
                      style={dashboardStyles.top_tile_numbers_font}
                    >{`${numberOfStates}`}</div>
                    <p>Locations</p>
                  </div>
                </div>
                <div className="col-6" style={componentStyling.percentageStatusPadding}>
                  {/* <GraphBars /> */}
                  GraphBars goes here
                </div>
                <div style={dashboardStyles.top_tile_status_font}>
                  x% Increase from last month
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* ./col */}
        <div className="col-lg-3 col-6">

          {/* small box */}
          <div style={dashboardStyles.head_tiles_color_light_green}>

            <div className='container'>
              <div className="row">
                <div className="col-6" style={componentStyling.statusInfoPadding}>
                  <div
                    className="inner"
                    style={dashboardStyles.top_tile_text}
                  >
                    <div
                      style={dashboardStyles.top_tile_numbers_font}
                    >100k</div>
                    <p>Customers</p>
                  </div>
                </div>
                <div className="col-6" style={componentStyling.percentageStatusPadding}>
                  {/* <GraphBars /> */}
                  GraphBars goes here
                </div>
                <div style={dashboardStyles.top_tile_status_font}>
                  x% Increase from last month
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* ./col */}
        <div className="col-lg-3 col-6">
          {/* small box */}
          <div style={dashboardStyles.head_tiles_color_red}>

            <div className='container'>
              <div className="row">
                <div className="col-6" style={componentStyling.statusInfoPadding}>
                  <div
                    className="inner"
                    style={dashboardStyles.top_tile_text}
                  >
                    <div
                      style={dashboardStyles.top_tile_numbers_font}
                    >{`40`}</div>
                    <p>Complaints</p>
                  </div>
                </div>
                <div className="col-6" style={componentStyling.percentageStatusPadding}>
                  {/* <GraphBars goes here /> */}
                  GraphBars goes here
                </div>
                <div style={dashboardStyles.top_tile_status_font}>
                  x% Decrease from last month
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* ./col */}
        <div className="col-lg-3 col-6">
          {/* small box */}
          <div style={dashboardStyles.head_tiles_color_light_green}>

            <div className='container'>
              <div className="row">
                <div className="col-6" style={{ paddingLeft: "4%" }}>
                  <div
                    className="inner"
                    style={dashboardStyles.top_tile_text}
                  >
                    <div style={dashboardStyles.top_tile_numbers_font}>100</div>
                    <p>Tons</p>
                  </div>
                </div>
                <div className="col-6" style={componentStyling.percentageStatusPadding}>
                  <img
                    style={styles.recyclops_menu_logo}
                    src={symbolwhite}
                    alt="newLogo"
                  />
                </div>
                <div style={dashboardStyles.top_tile_status_font}>
                  Amount of material recycled
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* ./col */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    getLocations: state.getLocations,
    menuCollapsed: state.menuCollapsed.menuCollapsed,
  };
};

export default connect(mapStateToProps)(TopStatusBoxes);
