import { useHistory } from "react-router-dom";
import menuStyles from './MenuStyles'
import { connect } from "react-redux";

function ProfileButton({ getCurrentUser }) {
  const history = useHistory();

  function handleClick() {
    history.push("/profile");
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col text-center">
          <button
            onClick={handleClick}
            style={menuStyles.menu_department_button_center}
            className="btn btn-default"
          >
            <i className="fas fa-user" />
            <span style={{ paddingLeft: "10%", fontWeight: 'bold' }}>{`${getCurrentUser ? getCurrentUser.name : ''}`}</span>
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    updatedData: state.updateCurrentAwsUser,
    getCurrentUser: state.getCurrentUser.userData,
    menuCollapsed: state.menuCollapsed.menuCollapsed,
  };
};

export default connect(mapStateToProps)(ProfileButton);


