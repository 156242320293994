/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App1 from './App1';
import reportWebVitals from './reportWebVitals';
import Amplify, { Auth } from 'aws-amplify';

// redux
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";

import menuCollapsed from "./components/redux/store/menuCollapsed";
import counter from "./components/redux/store/counter";
import updateUserProfile from "./components/redux/store/updateUserProfile";
import getCurrentUser from './components/redux/store/getCurrentUser'
import getLocations from './components/redux/store/locationsApi'
import menuCollapse from './components/redux/store/menuCollapse'

import awsconfig from './aws-exports'

// Amplify.configure(awsconfig);
// >>New - Configuring Auth Module

Auth.configure(awsconfig);


const combinedReducers = combineReducers({
  counter,
  menuCollapsed,
  updateUserProfile,
  getCurrentUser,
});

const store = createStore(
  combinedReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
  <Provider store={store}>
    <App1 />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
