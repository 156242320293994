/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:b2618a2e-4174-472e-a97f-1c31ec877c47",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_6K7C1NOBW",
    "aws_user_pools_web_client_id": "3p6ejf6qhmd1nhopt8ulqbah65",
    "oauth": {
        "domain": "thecommandcenter3dd251bd-3dd251bd-production.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://commandcenter.recyclops.com/",
        "redirectSignOut": "https://commandcenter.recyclops.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    }
};


export default awsmobile;
