const menuClosedStyles = {
  menu_icons: {
    color: "white",
    fontSize: "17px",
    paddingLeft: "20.5px",
  },
  menu_bubble_departments: {
    color: "white",
    background: "rgba(255, 255, 255, 0.5)",
    borderRadius: "20px",
    padding: "10%",
    width: "100%",
    borderColor: "rgba(255, 255, 255, 0.5)",
    borderWidth: "1px 0 1px 0",
    borderStyle: "solid",
  },
  menu_bubble_profile: {
    color: "white",
    background: "rgba(255, 255, 255, 0.5)",
    borderRadius: "20px",
    padding: "10%",
    width: "1%",
    paddingLeft: "15%",
    borderColor: "rgba(255, 255, 255, 0.5)",
    borderWidth: "1px 0 1px 0",
    borderStyle: "solid",
  },
};

export default menuClosedStyles;
