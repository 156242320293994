/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "../../AuthStyling";
import { locationApi } from "../helpers/api_services/fetchLocations";
import CloseMenu from "../menu/closeMenu";

class Locations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listOfstateArr: []
    };
  }
  async componentDidMount() {
    console.log("Locations page");
    // api call for location
    try {
      const fetchApi = await locationApi();
      const locationData = await fetchApi;

      if (locationData) {
        let locationDataAlpha = locationData.sort((a, b) =>
          a.state > b.state ? 1 : b.state > a.state ? -1 : 0
        );

        this.setState({
          listOfstateArr: [...locationData],
        });
      }
    } catch (e) {
      return null;
    }
  }

  render() {
    return (
      <div>
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <div onClick={this.props.menuCollapsedBool}>
            <CloseMenu />
          </div>
          <div className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-10">
                  <h1 className="m-0">Locations Page</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    getLocations: state.getLocations,
    menuCollapsed: state.menuCollapsed.menuCollapsed,
  };
};

const mapDispachToProps = (dispach) => {
  return {
    menuCollapsedBool: () => dispach({ type: "MENU_COLLAPSE" }),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(Locations)
