/* eslint-disable */
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import Dashboard from "../dashboard/dashboard";
import Header from "../header/header";
import Menu from "../menu/menu";
import Footer from "../footer/footer";

import ProfileLayout from '../profile/profileLayout'
import Settings from '../settings/settings'
import Locations from '../locations/locations'
import Logout from "../logout/logout";
import PageTemplate from '../page_template/template'

import dashboardStyles from '../dashboard/DashboardStyling'

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { profileClicked: true };
  }

  render() {
    return (
      <div className="wrapper">
        <Router>
          <section className="section">
            <Menu checkUser={this.props.checkUser} />
            <div className="" style={dashboardStyles.dashboard_background_color}>
              <Switch>
                <Route exact path="/index.html" component={Dashboard} />
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/profile" component={ProfileLayout} />
                <Route exact path="/settings" component={Settings} />
                <Route exact path="/locations" component={Locations} />
                <Route exact path="/pagetemplate" component={PageTemplate} />
                <Route exact path="/logout" component={Logout} />
              </Switch>
            </div>
          </section>
        </Router>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData
  };
};
export default connect(mapStateToProps)(Layout);
