const initialState = {
  menuCollapsed: false,
};

const menuCollapsed = (state = initialState, action) => {
  const newState = { ...state };

  if (action.type === "MENU_COLLAPSE") {
    newState.menuCollapsed = !newState.menuCollapsed;
  }

  return newState;
};


export default menuCollapsed;
