/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import CloseMenu from "../menu/closeMenu";
import menuStyles from '../menu/MenuStyles'

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const { menuCollapsed } = this.props;
    return (
      <div>
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <div onClick={this.props.menuCollapsedBool}>
            <CloseMenu />
          </div>
          <div
            className="content-header"
            style={menuCollapsed ? menuStyles.disable_menu_expand_hover : null}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-10">
                  <h1 className="m-0">Settings Page</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    getLocations: state.getLocations,
    menuCollapsed: state.menuCollapsed.menuCollapsed,
  };
};

const mapDispachToProps = (dispach) => {
  return {
    menuCollapsedBool: () => dispach({ type: "MENU_COLLAPSE" }),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(Settings)
