/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import dashboardStyles from '../../DashboardStyling'

const componentStyling = {
  barBorderRadius: {
    borderRadius: "20px",
  },
  topPadding: {
    paddingTop: "5%",
  }
}

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchState: ''
    }
  }

  searchBarHandler = (e) => {
    this.setState({
      searchState: e.target.value
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const { searchState } = this.state

    if (searchState) {
      alert(`search far basic function works ${searchState}`)

    }

    this.setState({
      searchState: ''
    })

  }

  render() {
    return (
      <div className='float-right' style={componentStyling.topPadding}>
        <form onSubmit={this.handleSubmit}>
          <input
            style={dashboardStyles.search_bar_styling}
            type="text"
            placeholder="      SearchBar.."
            className="fas fa-search"
            onChange={this.searchBarHandler}
            value={this.state.searchState}
          ></input>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    getLocations: state.getLocations,
    menuCollapsed: state.menuCollapsed.menuCollapsed,
  };
};

export default connect(mapStateToProps)(SearchBar);
