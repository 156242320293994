/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

class Header extends React.Component {
  render() {
    return (
      <div>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          {/* Left navbar links */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="pushmenu"
                href="#"
                role="button"
              >
                <i className="fas fa-bars" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    getCurrentUser: state.getCurrentUser.userData
  };
};

export default connect(mapStateToProps)(Header);
