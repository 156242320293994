/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import dashboardStyles from '../../DashboardStyling'

const componentStyling = {
    titlePadding: {
        padding: '3% 0% 0% 2%',
      },
    contentPadding: {
        marginTop: '-1%',
        padding: '0% 2% 0% 2%',
    },
    title_font:{
        fontWeight: 'bold',
        fontSize: '105%'
    },
    centerDivider1: {
        borderRight:'1px solid #2A9B8D',
        height:'80px',
    },
    centerDivider2: {
        borderLeft:'1px solid #2A9B8D', 
        height:'80px',
    }
}

class RecyclopsPricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div>

        <div style={dashboardStyles.BottomBoxesLayoutTopColor}>
            <div style={componentStyling.titlePadding}>
                <div className='container' style={componentStyling.title_font}>Recyclops Pricing</div>
            </div>
        </div>
        
        <div style={componentStyling.contentPadding}>
            <div className="row">
                <div className="col-sm" style={componentStyling.centerDivider1}>
                    <div className='container'>
                        <div>Monthly Subscription</div>
                        <div>Basic $12/month</div>
                        <div>Basic $18/month</div>
                    </div>
                </div>

                <div className="col-sm" style={componentStyling.centerDivider2}>
                    <div className='container' style={{paddingLeft: '50%'}}>
                        <div>Annual Subscription</div>
                        <div>Basic $144/year</div>
                        <div>Plus $216/year</div>
                    </div>
                </div>
                
            </div>  
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    getLocations: state.getLocations,
    menuCollapsed: state.menuCollapsed.menuCollapsed,
  };
};

export default connect(mapStateToProps)(RecyclopsPricing);
