/* eslint-disable */
import React, { Link } from "react";
import { connect } from "react-redux";
import styles from "../../AuthStyling";
import { NavLink } from "react-router-dom";

import dashboardStyles from "../dashboard/DashboardStyling";
import menuStyles from "../menu/MenuStyles";
import menuClosedStyles from "../menu/MenuClosedStyles";

import whitelogo from "../.././whitelogo.png";
import symbolwhite from "../.././symbolwhite.png";

import ProfileButton from "../menu/profileButton";
import DepartmentsButton from "../menu/departmentsButton";
import CloseMenu from "../menu/closeMenu";

import Amplify, { Auth } from "aws-amplify";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuCollapsed: false,
    };
  }

  render() {
    const { menuCollapsed, checkUser } = this.props;
    return (
      <div style={{ fontSize: '90%' }}>
        <aside
          className="main-sidebar elevation-4 "
          style={dashboardStyles.dashboard_theme}
        >
          <br />
          <br />
          <br />
          <div>
            {menuCollapsed ? (
              <img
                style={styles.recyclops_menu_logo}
                src={symbolwhite}
                alt="newLogo"
              />
            ) : (
              <img
                style={styles.recyclops_menu_logo}
                src={whitelogo}
                alt="Logo"
                className="center"
              />
            )}
          </div>
          <br />
          {/* Sidebar */}
          <div className="sidebar">
            <div style={menuStyles.dividerLine}></div>
            {/* Sidebar user panel (optional) */}
            <div style={dashboardStyles.dividerLine}></div>
            <br />

            <div>
              {menuCollapsed ? (
                <div style={menuClosedStyles.menu_bubble_departments}></div>
              ) : (
                <DepartmentsButton />
              )}
            </div>

            <br />

            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >

                <li className="nav-item">
                  <NavLink
                    href="#"
                    className="nav-link menu_item_selected_highlight"
                    to="/"
                    exact
                    style={menuStyles.menu_item_layout_style}
                  >
                    <i className="nav-icon fas fa-home" />
                    <p>Dashboard</p>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    href="#"
                    to="/locations"
                    exact
                    className="nav-link menu_item_selected_highlight"
                    style={menuStyles.menu_item_layout_style}
                  >
                    <i className="nav-icon fas fa-circle" />
                    <p>Locations</p>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    href="#"
                    to="/pagetemplate"
                    exact
                    className="nav-link menu_item_selected_highlight"
                    style={menuStyles.menu_item_layout_style}
                  >
                    <i className="nav-icon fas fa-circle" />
                    <p>Template</p>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <a
                    href="#"
                    className="nav-link"
                    style={menuStyles.menu_item_layout_style}
                  >
                    <i className="nav-icon fas fa-circle" />
                    <p>Blank</p>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    href="#"
                    className="nav-link"
                    style={menuStyles.menu_item_layout_style}
                  >
                    <i className="nav-icon fas fa-circle" />
                    <p>Blank</p>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    href="#"
                    className="nav-link"
                    style={menuStyles.menu_item_layout_style}
                  >
                    <i className="nav-icon fas fa-circle" />
                    <p>Blank</p>
                  </a>
                </li>

                {/* Second half of menu */}

                <div style={menuStyles.lower_menu_items}></div>

                <div>
                  {menuCollapsed ? (
                    <div
                      style={menuClosedStyles.menu_bubble_departments}
                    ></div>
                  ) : (
                    <ProfileButton />
                  )}
                </div>

                <br />
                <br />

                <li className="nav-item">
                  <NavLink
                    href="#"
                    to="/settings"
                    exact
                    className="nav-link menu_item_selected_highlight"
                    style={menuStyles.menu_item_layout_style}
                  >
                    <i className="nav-icon fas fa-cog" />
                    <p>Settings</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    href="#"
                    to="/logout"
                    exact
                    className="nav-link menu_item_selected_highlight"
                    style={menuStyles.menu_item_layout_style}
                  >
                    <i className="nav-icon fas fa-sign-out-alt" />
                    <p>Logout</p>
                  </NavLink>
                </li>
              </ul>
            </nav>
            {/* /.sidebar-menu */}
          </div>
          {/* /.sidebar */}
        </aside>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    menuCollapsed: state.menuCollapsed.menuCollapsed,
  };
};

const mapDispachToProps = (dispach) => {
  return {
    menuCollapsedBool: () => dispach({ type: "MENU_COLLAPSE" }),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(Menu);
